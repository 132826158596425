<template>
    <main>
        <section
            class="flex items-center justify-center bg-center bg-cover h-screenMinusMobileHeader md:h-screenMinusHeader"
            style="background-image: url('/images/about-us.jpg')"
        >
            <span class="max-w-xl text-center text-white standard-heading">
                ABOUT US
            </span>
        </section>

        <section class="px-8 py-24 text-center">
            <h2 class="pb-8 text-black standard-subheading">
                WE ARE DIEWERT FOUNDATION
            </h2>

            <div class="flex flex-col items-center">
                <p class="max-w-4xl">
                    The Gordon L. Diewert Foundation is named in memoriam of Dr. Gordon L Diewert.
                </p>

                <p class="max-w-3xl pt-4">
                    In April 1981, a drunk driver was responsible for taking Dr. Diewert, age 30, 
                    from his family. Dr. Diewert was a community builder and went out of his way 
                    to make his community a better place for all. Drawing from his inspirations, 
                    his wife, Susan, has set up the Diewert Foundation expecting to offer awards, 
                    scholarships and financial assistances to students and their families facing 
                    financial hardship in particular regarding food insecurity.
                </p>

                <p class="max-w-3xl pt-4">
                    The Foundation has adopted and incorporated Dr. Diewert's personal traits 
                    into a formal process. Together with the Xover team of dedicated software 
                    engineers and business professionals, the Foundation is ready to launch its 
                    perpetual funding mechanism to help generate money to support families in need.
                </p>
            </div>
        </section>

        <!-- <section class="px-8 pb-24 text-center">
            <h2 class="pb-8 text-black standard-subheading">
                PARTNERS
            </h2>

            <div class="flex flex-col items-center md:flex-row md:items-start md:justify-center">
                <div class="w-16 h-16 m-4 bg-gray-300 rounded-full"></div>

                <div class="w-16 h-16 m-4 bg-gray-300 rounded-full"></div>

                <div class="w-16 h-16 m-4 bg-gray-300 rounded-full"></div>

                <div class="w-16 h-16 m-4 bg-gray-300 rounded-full"></div>

                <div class="w-16 h-16 m-4 bg-gray-300 rounded-full"></div>
            </div>
        </section> -->
    </main>
</template>

<script>
export default {
    name: 'AboutUs',

    props: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

};
</script>
